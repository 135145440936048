.testimoniels{
    padding: 0 2rem;
    display: flex;
    gap: 1rem;
}
.left-t{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    color: white;
    text-transform: uppercase;
    gap: 2rem;
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.left-t>:nth-child(2),
.left-t>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.testimonial-data{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.testimonial-data>:nth-child(1){
    text-transform: none;
    letter-spacing: 2px;
    text-align: justify;
    line-height: 40px;
}
.right-t{
    flex: 1;
    position: relative;
   
}
.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    top: 2rem;
    right: 8rem;
}
.right-t>:nth-child(1){
    background-color: transparent;
    position: absolute;
    width: 17rem;
    height: 20rem;
    top: 1rem;
    right: 9rem;
    border: 2px solid orange;
}
.right-t>:nth-child(2){
    background: var(--planCard);
    position: absolute;
    height: 20rem;
    width: 17rem;
    top: 3rem;
    right: 7rem;

}
.arrows{
    position: absolute;
    bottom: 1rem;
    left: 3rem;
    display: flex;
    gap: 1rem;
}
.arrows>img{
    cursor: pointer;
    width: 1.5rem;
}
@media screen and (max-width: 768px) {
    .testimoniels{
        flex-direction: column;
        
    }
   .left-t>:nth-child(2),
   .left-t>:nth-child(3){
    font-size: xx-large;
   }
   .right-t{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-direction: column;
   }
   .right-t>div{
    position: relative;
    display: none;
   }
   .right-t>img{
    top: 0;
    right: 0;
    position: relative;
   }
   .right-t>:last-child{
    display: block;
    bottom: 0;
    left: 0;
   }
    
}