.footer-container>hr{
    border: 1px solid var(--orange);
}
.footer-container{
    position: relative;
}
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 2rem;
    gap: 4rem;
    height: 20rem;
}
.social-link{
   display: flex;
    gap: 4rem;
}
.social-link>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 15rem;

}
.footer-blur-1{
    width: 35rem;
    height: 15rem;
    left: 25rem;
    top: 5rem;
}
